import type { VNodeRef } from 'vue';

/** input 输入组件，获取焦点时自动全选
 *
 * @param e input 组件的原生 focus 事件参数
 */
export const onFocusSelect = (e: FocusEvent): string | void => {
  const inputElement = e.currentTarget as HTMLInputElement;
  if (inputElement) {
    inputElement.select();
    const selectedText = inputElement.value.substring(inputElement.selectionStart!, inputElement.selectionEnd!);
    return selectedText;
    // if (selectedText) {
    //   // 直接调用剪贴板 API，不关心返回结果
    //   navigator.clipboard.writeText(selectedText);
    //   // .then(() => Toaster.success(`${selectedText} 已复制到剪贴板`));
    // }
  }
};

export const setTblQtyCellRef = (cacheObj: Map<number, VNodeRef>, rowIndex: number, el: any) => {
  cacheObj.set(rowIndex, el);
};

/** 回车后光标垂直移动到下一行
 *
 * @param cacheObj ref组件集合
 * @param rowIndex 响应事件的ref在组件集合中的索引号
 * @param isPkgElm 组件是否为自封装组件
 */
export const moveToNextRow = (cacheObj: Map<number, VNodeRef>, rowIndex: number, isPkgElm?: boolean) => {
  const idx = rowIndex + 1;
  const ipt = (cacheObj.get(idx) || cacheObj.get(0)) as unknown;
  if (ipt) {
    if (isPkgElm) {
      // @ts-ignore
      (ipt as HTMLInputElement).onFocus();
    } else {
      (ipt as HTMLInputElement).focus();
    }
  }
};

export const onFocusMove = (e: 'up' | 'down' | 'left' | 'right') => {
  console.log(e);
  if (e === 'left' || e === 'right') {
    const eTab = new KeyboardEvent('keydown', {
      key: 'Tab',
      bubbles: true,
      cancelable: true,
      shiftKey: false, // 是否按下 Shift 键
      ctrlKey: false, // 是否按下 Ctrl 键
      altKey: false, // 是否按下 Alt 键
      metaKey: false // 是否按下 Meta 键(Windows 键或 Command 键)
    });
    const activeElement = document.activeElement;
    console.log(activeElement);
    if (activeElement) {
      activeElement.dispatchEvent(eTab);
    }
  }
};
