<template>
  <ViewHomeCard>
    <div class="userCardWrapper noSelect">
      <span class="topInfo">
        <span class="baseInfo">
          <span class="fontMd fontW7 bottomLine">{{ getNamezh }} <span class="fontSm fontW1 ml1">{{ userInfo.deptNamezh || '' }}</span></span>
          <span class="fontSm fontW1">关联账号：{{ userInfo.code }}</span>
        </span>
        <span class="avatorInfo flexXY">
          <IconUserHead :avatar-size="48"></IconUserHead>
        </span>
      </span>
      <span class="midInfo">
        <span class="dateInfo fontSm fontW1">
          <span class="fontW4">{{ dateInfo.date }}</span>
          <span>{{ dateInfo.dateCn }}</span>
          <span class="fontW7">{{ dateInfo.solar }}</span>
        </span>
        <span class="weekInfo pt2 pr2">{{ dateInfo.week }}</span>
      </span>
      <div class="flexY fullW fontSm topLine mt2 pr1 bottomInfo">{{ appMgr.appName }}&nbsp;/&nbsp;v{{ appMgr.appVer }}</div>
    </div>
  </ViewHomeCard>
</template>

<script setup lang="ts">
import { tDate } from 'shangshi_core';
import { computed } from 'vue';
import ViewHomeCard from '@/components/home/ViewHomeCard.vue';
import { useUser } from '@/stores/sys/useUser';
import { useApp } from '@/stores/sys/useApp';
import IconUserHead from '@/components/icons/IconUserHead.vue';

const userInfo = useUser().getUser;
const appMgr = useApp().getApp;

const dt = tDate();
const dateInfo = {
  week: dt.getWeek('zh'),
  date: dt.format(),
  dateCn: dt.formatAsLunar(),
  solar: dt.solar
};

const getNamezh = computed(() => {
  return userInfo.employeeNamezh || userInfo.namezh || '页面失效请刷新';
});
</script>

<style lang="scss" scoped>
$colorShadow: rgba(27, 67, 197, 0.911);

.userCardWrapper {
  display: grid;
  grid-template-rows: 80px 1fr 32px;

  .topInfo {
    display: grid;
    grid-template-columns: 1fr 80px;
    column-gap: 48px;

    .baseInfo {
      display: grid;
      grid-template-rows: 24px 24px 24px 1fr;
      row-gap: 8px;
    }

    .avatorInfo {
      position: relative;
      width: 64px;
      height: 64px;
      border: none;
      border-radius: 50%;
      // filter: drop-shadow(2px 4px 6px rgba(27, 67, 197, 0.911));
      overflow: hidden;

      .avatorImg {
        border-radius: 50%;
        // box-shadow: 1px 1px 40px 2px $colorShadow;
        // filter: drop-shadow(2px 4px 6px rgba(27, 67, 197, 0.911));
        filter: drop-shadow(0 0 6px #1B43C5);
        // filter: drop-shadow(2px 4px 6px var(--proj-main-color));
      }
    }
  }

  .midInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .dateInfo {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 8px;
    }

    .weekInfo {
      font-size: 28px;
      font-weight: 400;
      text-align: right;
      // color: var(--proj-main-color);
    }
  }

  .bottomInfo {
    padding-top: 12px;
    justify-content: flex-end;
  }
}
</style>
